import { render, staticRenderFns } from "./appraisalResult.vue?vue&type=template&id=024525c3&scoped=true&"
import script from "./appraisalResult.vue?vue&type=script&lang=js&"
export * from "./appraisalResult.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "024525c3",
  null
  
)

export default component.exports