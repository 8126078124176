<template>
  <div>
    <fm-title
      title-text="考核结果"
      :title-menus="[{key: 're', label: '重算结果'}, {key: 'output', label: '导出'}, {key: 'mx', label: '明细'}]"
      @clickTitleMenu="clickTitleMenu">
    </fm-title>
    <fm-table-new
      @on-column-config-update="(columns) => $onTableColumnUpdate(columns, 'main')"
      size="small"
      ref="artable"
      :toolbox="showToolBox"
      :export-file-name="appraisalTitle + '结果'"
      :columns="columns"
      :data-list="bkhList"
      :simple-filter="true"
      emptyPlaceholder="-"
      border="row"
      :stripe="false">
    </fm-table-new>
    <fm-modal
      :value="openDialogMx"
      :mask-closable="false"
      width="80%"
      v-if="openDialogMx"
      theme="mh-withe"
      @cancel="openDialogMx = false">
      <fm-title
        :title-text="appraisalType === 'vote' ? '投票明细' : '得分明细'">
      </fm-title>
      <fm-table-new
        size="small"
        @on-column-config-update="(columns) => $onTableColumnUpdate(columns, appraisalType === 'vote' ? 'vote-details' : 'score-details')"
        :columns="appraisalType === 'vote' ? mxColumnsVote : mxColumns"
        :data-list="mxList"
        :border="true"
        :simple-filter="true"
        :emptyPlaceholder="appraisalType === 'vote' ? '未投票' : '未打分'"
        :stripe="false">
      </fm-table-new>
    </fm-modal>
  </div>
</template>

<script>
import {
  appraisalGroupDetailRequest,
  appraisalResultRequest
} from '../../../api'

export default {
  name: 'appraisal-result',
  props: {
    appraisalId: {
      type: [String, Number]
    },
    appraisalTitle: {
      type: String
    },
    appraisalType: {
      type: String
    },
    isInc: {
      type: String
    }
  },
  data () {
    return {
      bkhList: [],
      openDialogMx: false,
      checkList: [],
      mxList: []
    }
  },
  computed: {
    showToolBox () {
      return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
    },
    baseMxColumns () {
      let data = [{field: 'beUserName', title: '', width: 100}].concat(this.checkList.map((v) => {
        return {
          field: this.lm ? 'lm_' + v.id : v.id,
          title: this.isInc === 'yes' ? '**' :v.userName,
          width: 80
        }
      }))
      return data
    },
    mxColumns () {
      return this.$getTableConfig('score-details', this.baseMxColumns)
    },
    baseMxColumnsVote () {
      let data = [{field: 'beUserName', title: '', width: 100}].concat(this.checkList.map((v) => {
        return {
          field: v.id,
          title: this.isInc === 'yes' ? '**' :v.userName,
          render: (h, rowData) => {
            return h('div', rowData[v.id] ? rowData[v.id] : '0')
          }
        }
      }))
      return data
    },
    mxColumnsVote () {
      return this.$getTableConfig('vote-details', this.baseMxColumnsVote)
    },
    baseColumns () {
      let data = [{
        field: 'appraisalGroupName',
        title: '分组',
        width: 150,
        sort: true
      },
      {
        field: 'userName',
        title: '被考核人',
        width: 150,
        sort: true
      },
      {
        field: 'represent',
        title: '代表单位'
      },
      {
        field: 'value',
        title: this.appraisalType === 'vote' ? '票数' : '分值',
        sort: true,
        width: 150,
        dataType: Number
      },
      {
        field: 'ranking',
        title: '名次',
        sort: true,
        width: 150,
        dataType: Number
      },
      {
        field: 'gotta',
        title: '得奖',
        sort: true
      }]
      // let a = [{
      //   title: '操作',
      //   slot: 'actions',
      //   width: 100,
      //   fixed: true
      // }]
      return data
    },
    columns () {
      return this.$getTableConfig('main', this.baseColumns)
    },
  },
  methods: {
    loadMxList () {
      this.mxList = []
      let parm = {
        appraisalId: this.appraisalId
      }
      appraisalResultRequest.getDetail(parm).then((data) => {
        let dataMap = {}
        data.forEach((v) => {
          dataMap[v.beAppraisalGroupDetailId] = dataMap[v.beAppraisalGroupDetailId] || {}
          dataMap[v.beAppraisalGroupDetailId][v.appraisalGroupDetailId] = (dataMap[v.beAppraisalGroupDetailId][v.appraisalGroupDetailId] || 0) + v.value
        })
        this.bkhList.forEach((v) => {
          this.mxList.push(Object.assign({
            beUserName: v.userName,
            id: v.id
          }, dataMap[v.id]))
        })
      })
    },
    loadCheckList () {
      let parm = {
        appraisalId: this.appraisalId,
        appraisalGroupType: 'check'
      }
      appraisalGroupDetailRequest.get(parm).then((data) => {
        this.checkList = data
      })
    },
    async tj () {
      this.loading = true
      await appraisalResultRequest.calculate(this.appraisalId)
      this.loading = false
      this.loadData()
    },
    clickTitleMenu (parm) {
      if (parm === 're') {
        this.tj()
      } else if (parm === 'mx') {
        this.loadMxList()
        this.openDialogMx = true
      } else if (parm === 'output') {
        this.$refs.artable.openExport()
      }
    },
    async loadData () {
      let parm = {
        appraisalId: this.appraisalId,
        appraisalGroupType: 'becheck'
      }
      this.bkhList = await appraisalGroupDetailRequest.get(parm)
      let resultList = await appraisalResultRequest.get({
        appraisalId: this.appraisalId
      })
      this.bkhList.forEach(v => {
        let result = resultList.find(v1 => v1.beAppraisalGroupDetailId === v.id)
        v.value = result ? result.value : null
        v.ranking = result ? result.ranking : null
        v.gotta = result ? result.gotta : null
      })
      this.bkhList.sort((a, b) => {
        console.log(a, b)
        return (a.appraisalGroupId * 1000 + (a.ranking || 0)) - (b.appraisalGroupId * 1000 + (b.ranking || 0))
      })
    }
  },
  created () {
    if (this.appraisalId) {
      this.loadData()
      this.loadCheckList()
    }
  }
}
</script>

<style lang="less" scoped>
</style>