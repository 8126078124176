<template>
  <div class="status">
    <div class="status-d" :style="{background: statusColorMap[status]}"></div>
    <span>{{statusText}}</span>
  </div>
</template>

<script>
export default {
  props: {
    status: {type: String, default: ''}
  },
  data () {
    return {
      statusColorMap: {
        'doing': '#2F5BEA',
        'plan': '#34D0DE',
        'over': '#52C41A',
        'void': '#FF2424'
      }
    }
  },
  computed: {
    statusText () {
      return (this.statusList.find(v1 => v1.key === this.status) || {label: '--'}).label
    },
    statusList () {
      return this.$store.getters.appraisalStatusList
    }
  }
}
</script>

<style lang="less" scoped>
.status {
  display: flex;
  align-items: center;
  .status-d {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 9px;
  }
}
</style>