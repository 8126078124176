<template>
  <div class="search-input">
    <input type="text" v-model="searchText" @blur="change" :placeholder="placeholder">
    <div>
      <i class="iconfont icon-search"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    serchText: {type: String, default: ''},
    placeholder: {type: String, default: '请输入'}
  },
  data () {
    return {
      searchText: ''
    }
  },
  methods: {
    change () {
      this.$emit('change', this.searchText)
    }
  }
}
</script>

<style lang="less" scoped>
.search-input {
  border: 1px solid #D9D9D9;
  background: #FAFAFA;
  width: 12rem;
  height: 2rem;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 0 12px 0 17px;
  input {
    width: 11rem;
    background: #FAFAFA;
    border: 0px;
  }
  i {
    font-size: 14px;
    color: #707070;
  }
}
</style>